jQuery(document).ready(function (e) {

	jQuery.ajaxSetup({
		cache: false,
		timeout: 5000,
		// dataType: "json",
		scriptCharset: "utf-8",
		contentType: "application/x-www-form-urlencoded",
		error: function (xhr, errorType, exceptionThrown) {
			if (xhr.status == 0) {
				return console.log("Você está offline! Por favor verifique sua rede.");
			} else if (xhr.status == 404) {
				return console.log("Diretório ou arquivo não está disponível no servidor");
			} else if (xhr.status == 500) {
				return console.log("Erro interno no servidor");
			} else if (errorType == "parsererror") {
				return console.log("Erro ao verificar os dados requeridos.");
			} else if (errorType == "timeout") {
				return console.log("Servidor excedeu o tempo limite de espera");
			} else {
				return console.log("Erro desconhecido: " + exceptionThrown);
			}
		},
		fail: function (xhr, status, errorThrown) {
			console.log("fail");
			console.log("Error: " + errorThrown);
			console.log("Status: " + status);
		}
	});

	$.extend($.validator.messages, {
		required: "Este campo &eacute; requerido.",
		remote: "Por favor, corrija este campo.",
		email: "Por favor, forne&ccedil;a um endere&ccedil;o eletr&ocirc;nico v&aacute;lido.",
		url: "Por favor, forne&ccedil;a uma URL v&aacute;lida.",
		date: "Por favor, forne&ccedil;a uma data v&aacute;lida.",
		dateISO: "Por favor, forne&ccedil;a uma data v&aacute;lida (ISO).",
		number: "Por favor, forne&ccedil;a um n&uacute;mero v&aacute;lido.",
		digits: "Por favor, forne&ccedil;a somente d&iacute;gitos.",
		creditcard: "Por favor, forne&ccedil;a um cart&atilde;o de cr&eacute;dito v&aacute;lido.",
		equalTo: "Por favor, forne&ccedil;a o mesmo valor novamente.",
		accept: "Por favor, forne&ccedil;a um valor com uma extens&atilde;o v&aacute;lida.",
		maxlength: jQuery.validator.format("Por favor, forne&ccedil;a n&atilde;o mais que {0} caracteres."),
		minlength: jQuery.validator.format("Por favor, forne&ccedil;a ao menos {0} caracteres."),
		rangelength: jQuery.validator.format("Por favor, forne&ccedil;a um valor entre {0} e {1} caracteres de comprimento."),
		range: jQuery.validator.format("Por favor, forne&ccedil;a um valor entre {0} e {1}."),
		max: jQuery.validator.format("Por favor, forne&ccedil;a um valor menor ou igual a {0}."),
		min: jQuery.validator.format("Por favor, forne&ccedil;a um valor maior ou igual a {0}.")
	});

	jQuery.validator.addMethod("notEqual", function (value, element, param) {
		return this.optional(element) || value != jQuery(param).val();
	}, "Por segurança não é permitido repetir.");

	jQuery.validator.addMethod("dateBR", function (value, element) {
		if (value.length != 10) return false;
		// verificando data
		var data = value;
		var dia = data.substr(0, 2);
		var barra1 = data.substr(2, 1);
		var mes = data.substr(3, 2);
		var barra2 = data.substr(5, 1);
		var ano = data.substr(6, 4);
		if (data.length != 10 || barra1 != "/" || barra2 != "/" || isNaN(dia) || isNaN(mes) || isNaN(ano) || dia > 31 || mes > 12) return false;
		if ((mes == 4 || mes == 6 || mes == 9 || mes == 11) && dia == 31) return false;
		if (mes == 2 && (dia > 29 || (dia == 29 && ano % 4 != 0))) return false;
		if (ano < 1900) return false;
		return true;
	}, "Informe uma data válida");

	jQuery.validator.addMethod("viacep", function (value, element) {
		var cep = value.replace(/\.|\-/g, "");
		var result = null;
		jQuery.ajax({
			type: "GET",
			dataType: "json",
			url: "http://viacep.com.br/ws/" + cep + "/json/",
			cache: false,
			timeout: 5000,
			complete: function (msg) {
				msg = msg.responseJSON;
				if (!!msg.erro) {
					result = false;
				} else {
					result = true;
				}
			}
		});
		return result;
	}, "Por favor, digite um CEP válido.");

	jQuery.validator.addMethod("cep", function (value, element) {
		value = value.replace(/\.|\-/g, "");

		return this.optional(element) || /^[0-9]{8}$/.test(value);
	}, "Por favor, digite um CEP válido.");

	jQuery.validator.addMethod("dataBR", function (value, element) {
		var currVal = value;
		if (currVal == "")
			return false;

		//Declare Regex
		var rxDatePattern = /^(\d{1,2})(\/|-)(\d{1,2})(\/|-)(\d{4})$/;
		var dtArray = currVal.match(rxDatePattern); // is format OK?

		if (dtArray == null)
			return false;

		//Checks for mm/dd/yyyy format.
		var dtDay = parseInt(dtArray[1]);
		var dtMonth = parseInt(dtArray[3]);
		var dtYear = parseInt(dtArray[5]);
		var age = calculateAge(dtMonth, dtDay, dtYear);

		if (dtMonth < 1 || dtMonth > 12)
			return false;
		else if (dtDay < 1 || dtDay > 31)
			return false;
		else if ((dtMonth == 4 || dtMonth == 6 || dtMonth == 9 || dtMonth == 11) && dtDay == 31)
			return false;
		else if (dtMonth == 2) {
			var isleap = (dtYear % 4 == 0 && (dtYear % 100 != 0 || dtYear % 400 == 0));
			if (dtDay > 29 || (dtDay == 29 && !isleap))
				return false;
		} else if (age < 16 || age > 100)
			return false;

		return true;
	}, "Por favor, digite uma Data válida. Mínino 16 anos de idade.");

	function calculateAge(birth_month, birth_day, birth_year) {
		today_date = new Date();
		today_year = today_date.getFullYear();
		today_month = today_date.getMonth();
		today_day = today_date.getDate();
		age = today_year - birth_year;

		if (today_month < (birth_month - 1)) {
			age--;
		}
		if (((birth_month - 1) == today_month) && (today_day < birth_day)) {
			age--;
		}
		return age;
	}

	jQuery.validator.addMethod("cpf", function (value, element) {
		var cpf = value.replace(/[^\d]+/g, "");

		if (cpf == "")
			return false;
		if (cpf.length != 11)
			return false;

		if (cpf == "00000000000" || cpf == "11111111111" || cpf == "22222222222" || cpf == "33333333333" || cpf == "44444444444" || cpf == "55555555555" || cpf == "66666666666" || cpf == "77777777777" || cpf == "88888888888" || cpf == "99999999999")
			return false;
		add = 0;
		for (i = 0; i < 9; i++)
			add += parseInt(cpf.charAt(i)) * (10 - i);
		rev = 11 - (add % 11);
		if (rev == 10 || rev == 11)
			rev = 0;
		if (rev != parseInt(cpf.charAt(9)))
			return false;
		add = 0;
		for (i = 0; i < 10; i++)
			add += parseInt(cpf.charAt(i)) * (11 - i);
		rev = 11 - (add % 11);
		if (rev == 10 || rev == 11)
			rev = 0;
		if (rev != parseInt(cpf.charAt(10)))
			return false;
		return true;
	}, "Informe um CPF válido");

	function removeMaskTel(str) {
		str = str.replace(/\D/g, "");
		str = str.substring(2);
		// str = parseInt(str, 10);
		return str;
	}

	function removeMask(str) {
		str = str.replace(/\D/g, "");
		// str = parseInt(str, 10);
		return str;
	}

	function getDDDMaskTel(str) {
		str = str.replace(/\D/g, "");
		str = str.slice(0, 2);
		// str = parseInt(str, 10);
		return str;
	}

	function reverseDate(str) {
		str = str.split("/");
		str.reverse();
		str = str.join("-");
		return str;
	}

	function formatReal(int) {
		var tmp = int + "";
		tmp = tmp.replace(/([0-9]{2})$/g, ",$1");
		if (tmp.length > 6)
			tmp = tmp.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");

		return tmp;
	}

	function validarCPF(cpf) {
		cpf = cpf.replace(/\D/g, "");
		if (cpf == "") return false;
		if (cpf.length != 11 ||
			cpf == "00000000000" ||
			cpf == "11111111111" ||
			cpf == "22222222222" ||
			cpf == "33333333333" ||
			cpf == "44444444444" ||
			cpf == "55555555555" ||
			cpf == "66666666666" ||
			cpf == "77777777777" ||
			cpf == "88888888888" ||
			cpf == "99999999999")
			return false;
		add = 0;
		for (i = 0; i < 9; i++)
			add += parseInt(cpf.charAt(i)) * (10 - i);
		rev = 11 - (add % 11);
		if (rev == 10 || rev == 11)
			rev = 0;
		if (rev != parseInt(cpf.charAt(9)))
			return false;
		add = 0;
		for (i = 0; i < 10; i++)
			add += parseInt(cpf.charAt(i)) * (11 - i);
		rev = 11 - (add % 11);
		if (rev == 10 || rev == 11)
			rev = 0;
		if (rev != parseInt(cpf.charAt(10)))
			return false;
		return true;
	}

	function cepLoad() {
		jQuery("input[name=cep]").blur(function () {
			var cep = jQuery(this).val().replace(/\D/g, "");
			if (cep !== "") {
				var validacep = /^[0-9]{8}$/;
				if (validacep.test(cep)) {
					jQuery.ajax({
						type: "GET",
						dataType: "json",
						url: "http://viacep.com.br/ws/" + cep + "/json/",
						cache: false,
						timeout: 5000,
						beforeSend: function () {
							console.log("Consulta para verificar a existência do CEP <strong>" + cep + "</strong>");
						},
						complete: function (msg) {
							resp = msg.responseJSON;

							if (!!resp.erro) {
								console.log("Tivemos um pequeno problema ao consultar o CEP <strong>" + cep + "</strong>.<br> Por favor, confirme se o preenchimento está correto");
								jQuery("input[name=cep]").attr("data-cep", false);
								jQuery("input[name=cep]").val("");
							} else {
								jQuery("input[name=logradouro]").val(resp.logradouro);
								jQuery("input[name=bairro]").val(resp.bairro);
								jQuery("input[name=cidade]").val(resp.localidade);
								jQuery("input[name=estado]").val(resp.uf);
								jQuery("input[name=cep]").attr("data-cep", true);
							}
						}
					});
				} else {
					jQuery("input[name=cep]").attr("data-cep", false);
				}
			}
		});
	}

	function limpa_formulario_cep() {
		jQuery("input[name=logradouro]").val("");
		jQuery("input[name=bairro]").val("");
		jQuery("input[name=cidade]").val("");
		jQuery("input[name=estado]").val("");
	}

	if (!!jQuery('#formContato')) {
		jQuery('#formContato').validate({
			debug: false,
			errorClass: "error",
			errorElement: "div",
			onkeyup: false,
			rules: {
				nome: {
					required: true,
					minlength: 4
				},
				email: {
					required: true,
					email: true
				},
				telefone: {
					required: true
				},
				assunto: {
					required: true
				},
				representante: {
					required: true
				},
				mensagem: {
					required: true,
					minlength: 10
				}
			},
			messages: {
				nome: {
					required: 'Preencha o campo nome',
					minlength: 'No mínimo 4 letras'
				},
				email: {
					required: 'Informe o seu email',
					email: 'Ops, informe um email válido'
				},
				telefone: {
					required: 'Nos diga seu telefone.'
				},
				mensagem: {
					required: 'Deixe sua mensagem',
					minlength: 'No mínimo 10 letras'
				}
			}
		});
	}


	if (!!jQuery("input[name=cep]")) {
		jQuery("input[name=cep]").mask("00.000-000");
	}
	if (!!jQuery("input[name=data]")) {
		jQuery("input[name=data]").mask("00/00/0000");
	}
	if (!!jQuery("input[name=dtnascimento]")) {
		jQuery("input[name=dtnascimento]").mask("00/00/0000");
	}
	if (!!jQuery("input[name=nascimento]")) {
		jQuery("input[name=nascimento]").mask("00/00/0000");
	}
	if (!!jQuery("input[name=cpf]")) {
		jQuery("input[name=cpf]").mask("000.000.000-00");
	}
	if (!!jQuery("input[name=cnpj]")) {
		jQuery("input[name=cnpj]").mask("00.000.000/0000-00");
	}
	if (!!jQuery("input[name=telefone]")) {
		jQuery("input[name=telefone]").mask("(00) 0000-0000");
	}

	if (!!jQuery("input[name=numeroCartao]")) {
		jQuery("input[name=numeroCartao]").mask("0000 0000 0000 0000");
	}
	if (!!jQuery("input[name=validadeCartao]")) {
		jQuery("input[name=validadeCartao]").mask("00/00");
	}
	if (!!jQuery("input[name=codigoCartao]")) {
		jQuery("input[name=codigoCartao]").mask("000");
	}

	var SPMaskBehavior = function (val) {
			return val.replace(/\D/g, "").length === 11 ? "(00) 00000-0000" : "(00) 0000-00009";
		},
		spOptions = {
			onKeyPress: function (val, e, field, options) {
				field.mask(SPMaskBehavior.apply({}, arguments), options);
			}
		};

	if (!!jQuery("input[name=telefone2]")) {
		jQuery("input[name=telefone2]").mask(SPMaskBehavior, spOptions);
	}

	if (!!jQuery("input[name=celular]")) {
		var SPMaskBehavior = function (val) {
				return val.replace(/\D/g, "").length === 11 ? "(00) 00000-0000" : "(00) 0000-00009";
			},
			spOptions = {
				onKeyPress: function (val, e, field, options) {
					field.mask(SPMaskBehavior.apply({}, arguments), options);
				}
			};

		jQuery("input[name=celular]").mask(SPMaskBehavior, spOptions);
	}
});